<template>
  <div class="container my-5">
    <div class="row">
      <!-- Columna Izquierda: Detalles del Viaje -->
      <div class="col-md-6">
        <div class="trip-details">
          <h3 class="mb-4">Detalles del Viaje</h3>
          <ul class="list-group list-unstyled">
            <li><strong>Origen:</strong> {{ tripDetails.origin }}</li>
            <li><strong>Destino:</strong> {{ tripDetails.destination }}</li>
            <li><strong>Fecha y Hora:</strong> {{ formattedDateTime }}</li>
            <li><strong>Tipo de Viaje:</strong> {{ tripDetails.tripType }}</li>
            <li><strong>Vehículo Seleccionado:</strong> {{ tripDetails.vehicleSelected }}</li>
          </ul>

          <!-- Mapa con Mapbox -->
          <div class="map-container mt-4" ref="summaryMap"></div>
        </div>
      </div>

      <!-- Columna Derecha -->
      <div class="col-md-6">
        <div v-if="!paymentProcessed">
          <div class="payment-section">
            <h3 class="mb-4">Resumen del Pago</h3>
            <div class="payment-summary mb-4">
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <span><strong>Precio Estimado:</strong></span>
                  <span class="price">€{{ estimatedPrice.toFixed(2) }}</span>
                </li>
              </ul>
              <p class="mt-2">
                <strong>Nota:</strong> El precio mostrado es una estimación base. El precio final puede variar según el tráfico y otros factores.
              </p>
            </div>

            <!-- SISTEMA DE PUJA: Estilo trading + campo manual reducido -->
            <div class="trading-frame mb-4">
              <div class="trading-frame-header d-flex align-items-center justify-content-between">
                <label for="manualPrice" class="form-label mb-0">Sistema de Puja</label>
                <!-- Indicador de Porcentaje -->
                <div class="puja-info text-white" :class="pujaColorClass">
                  <span class="fw-bold">
                    Ajuste:
                    <template v-if="sliderValue === 100">0%</template>
                    <template v-else-if="sliderValue > 100">
                      +{{ (sliderValue - 100).toFixed(0) }}%
                    </template>
                    <template v-else>
                      -{{ (100 - sliderValue).toFixed(0) }}%
                    </template>
                  </span>
                </div>
              </div>

              <div class="trading-body mt-3">
                <div class="slider-section d-flex align-items-center">
                  <!-- Barra deslizante -->
                  <input
                    type="range"
                    class="form-range slider-trading"
                    min="50"
                    max="200"
                    step="1"
                    v-model.number="sliderValue"
                  />
                  <p class="text-muted small ms-2 mb-0">
                    Modifica la puja respecto a €{{ basePrice.toFixed(2) }}
                  </p>
                </div>

                <!-- Campo manual MUY compacto + botón para confirmar cambio -->
                <div class="manual-amount mt-3">
                  <label for="manualPrice" class="form-label small-label">Modificar Cantidad</label>
                  <!-- <-- mira aquí: input-group más pequeño y ancho controlado -->
                  <div class="input-group input-group-sm manual-input-container">
                    <span class="input-group-text">
                      <i class="mdi mdi-currency-eur"></i>
                    </span>
                    <!-- <-- en lugar de v-model="estimatedPrice", usamos manualPrice -->
                    <input
                      type="number"
                      step="0.01"
                      class="form-control manual-input"
                      id="manualPrice"
                      v-model.number="manualPrice" 
                      placeholder="Cantidad"
                    />
                    <!-- <-- botón para aplicar manualPrice al estimatedPrice -->
                    <button class="btn btn-secondary" type="button" @click="applyManualPrice">
                      Cambiar
                    </button>
                  </div>
                  <p class="text-muted small mt-2">
                    Ajusta manualmente tu puja y pulsa <strong>Cambiar</strong>. El slider se sincronizará con este valor.
                  </p>
                </div>
              </div>
            </div>
            <!-- FIN SISTEMA DE PUJA -->

            <!-- Formulario de Pago con Stripe Elements -->
            <form @submit.prevent="submitPayment">
              <div class="mb-3">
                <label for="card-element" class="form-label">Detalles de la Tarjeta</label>
                <div id="card-element" class="form-control">
                  <!-- Stripe Elements montará aquí el elemento de la tarjeta -->
                </div>
                <!-- Muestra errores de validación de tarjeta -->
                <div id="card-errors" role="alert" class="text-danger mt-2"></div>
              </div>

              <div class="mb-3">
                <label for="cardHolder" class="form-label">Titular de la Tarjeta</label>
                <div class="input-group input-group-sm">
                  <span class="input-group-text">
                    <i class="mdi mdi-account-outline"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="cardHolder"
                    v-model="cardHolder"
                    placeholder="Nombre Completo"
                    required
                  />
                </div>
              </div>

              <button type="submit" class="btn btn-primary w-100 solicitar-viaje-btn">
                Solicitar Viaje
              </button>
            </form>

            <!-- Iconos de confianza -->
            <div class="trust-icons text-center my-4">
              <div class="d-flex justify-content-center align-items-center gap-3 mb-3 payment-icons">
                <img
                  src="https://logos-world.net/wp-content/uploads/2020/04/Visa-Logo.png"
                  alt="Visa"
                  class="payment-icon"
                />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png"
                  alt="MasterCard"
                  class="payment-icon"
                />
              </div>
              <div class="secure-payment">
                <i class="mdi mdi-lock"></i> Pago Seguro
              </div>
              <p class="text-muted small mt-2">
                Tus datos están protegidos con encriptación avanzada.
              </p>
            </div>
          </div>
        </div>

        <!-- Animación de Asignación de Vehículo -->
        <div v-else>
          <div class="animation-section">
            <h3 class="mb-4 text-center">Asignando tu Vehículo</h3>
            <ul class="timeline">
              <li
                v-for="(step, index) in animationSteps"
                :key="index"
                :class="{ active: currentAnimationStep >= index }"
              >
                <div class="timeline-icon">
                  <i :class="step.icon"></i>
                </div>
                <div class="timeline-content">
                  <p>{{ step.message }}</p>
                </div>
                <div class="timeline-check" v-if="currentAnimationStep > index">
                  <i class="mdi mdi-check-circle-outline"></i>
                </div>
              </li>
            </ul>
            <!-- Iconos Vectoriales -->
            <div class="vector-images">
              <i class="mdi mdi-car vector-icon"></i>
              <i class="mdi mdi-steering vector-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'Pagos',
  data() {
    return {
      // Precio base (precio mínimo/estimación inicial)
      basePrice: 0.0,

      // Precio final que se enviará a la pasarela
      estimatedPrice: 0.0,

      // Slider en rango 50 - 200 (%)
      sliderValue: 100,

      // <-- mira aquí: variable para el input manual
      manualPrice: 0.0,

      tripDetails: {
        origin: localStorage.getItem('Origen') || 'Origen no especificado',
        destination: localStorage.getItem('Destino') || 'Destino no especificado',
        latitudePickup: parseFloat(localStorage.getItem('LatitudOrigen')) || null,
        longitudePickup: parseFloat(localStorage.getItem('LongitudOrigen')) || null,
        latitudeDestination: parseFloat(localStorage.getItem('LatitudDestino')) || null,
        longitudeDestination: parseFloat(localStorage.getItem('LongitudDestino')) || null,
        dateTime: localStorage.getItem('FechaYHora') || null,
        tripType: localStorage.getItem('TipoDeViaje') || 'No especificado',
        vehicleSelected: localStorage.getItem('VehiculoSeleccionado') || 'No especificado',
        // Forzamos siempre 'Card'
        paymentMethod: 'Card',
      },
      map: null,
      animationInterval: null,
      routeLayerId: 'route',
      cardHolder: '',
      paymentProcessed: false,
      currentAnimationStep: -1,
      animationSteps: [],
      stripe: null,
      elements: null,
      card: null,

      // Token de Mapbox
      mapboxAccessToken: 'pk.eyJ1Ijoic2FtaWdvcmFiIiwiYSI6ImNtMnNnbGhzdTFjZzEya3NibHd1ZzB0ZXQifQ.g7lY1cJ9CUqUR4uCC_fvBA',
    };
  },
  created() {
    // Lógica para la "animación" de asignación de vehículo
    const getRandomDuration = () => Math.floor(Math.random() * 7) + 1;

    this.animationSteps = [
      {
        message: 'Estamos buscando los mejores conductores en tu zona',
        duration: getRandomDuration(),
        icon: 'mdi mdi-map-search-outline',
      },
      {
        message: 'Asignando tu conductor',
        duration: getRandomDuration(),
        icon: 'mdi mdi-account-check-outline',
      },
      {
        message: 'Notificando tu posición al conductor',
        duration: getRandomDuration(),
        icon: 'mdi mdi-map-marker-radius',
      },
      {
        message: 'Preparando la ruta óptima',
        duration: getRandomDuration(),
        icon: 'mdi mdi-map-outline',
      },
      {
        message: '¡Tu conductor está en camino!',
        duration: getRandomDuration(),
        icon: 'mdi mdi-car-arrow-right',
      },
    ];
  },
  async mounted() {
    // Cargamos el precio base desde localStorage
    const storedPrice = parseFloat(localStorage.getItem('PrecioEstimado')) || 0.0;
    this.basePrice = storedPrice;
    this.estimatedPrice = storedPrice;

    // Inicializamos manualPrice con el valor del precio base
    this.manualPrice = this.estimatedPrice;

    // Inicializar mapa (Mapbox)
    this.initMap();

    // Inicializar Stripe (clave pública)
    this.stripe = await loadStripe('pk_test_51IzByQCM2RQN2snqU3FHG5huCPCUGujIwDSuN00aksxgKE15KB3WR2ZEfuGGOewgv3motYEcNym9YdAZOY9fcBhO00pIpfjvG6');
    this.elements = this.stripe.elements();

    // Crear y montar el elemento de la tarjeta
    this.card = this.elements.create('card', {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    });
    this.card.mount('#card-element');

    // Manejar cambios en el elemento de la tarjeta
    this.card.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      displayError.textContent = event.error ? event.error.message : '';
    });
  },
  beforeUnmount() {
    if (this.card) {
      this.card.destroy();
    }
    if (this.animationInterval) {
      clearInterval(this.animationInterval);
    }
  },
  watch: {
    // Cuando cambie el slider, recalculamos estimatedPrice con base al porcentaje
    sliderValue(newVal) {
      if (this.basePrice > 0) {
        const newPrice = this.basePrice * (newVal / 100);
        this.estimatedPrice = parseFloat(newPrice.toFixed(2));
        // Actualizamos manualPrice para que la próxima vez aparezca sincronizado
        this.manualPrice = this.estimatedPrice;
      } else {
        this.estimatedPrice = 0;
      }
    },
  },
  methods: {
    initMap() {
      const { latitudePickup, longitudePickup, latitudeDestination, longitudeDestination } = this.tripDetails;
      mapboxgl.accessToken = this.mapboxAccessToken;

      if (latitudePickup && longitudePickup && latitudeDestination && longitudeDestination) {
        this.map = new mapboxgl.Map({
          container: this.$refs.summaryMap,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [
            (longitudePickup + longitudeDestination) / 2,
            (latitudePickup + latitudeDestination) / 2,
          ],
          zoom: 12,
          interactive: false,
        });

        this.map.on('load', () => {
          this.getRouteAndAnimate();
        });
      }
    },
    async getRouteAndAnimate() {
      const { latitudePickup, longitudePickup, latitudeDestination, longitudeDestination } = this.tripDetails;
      try {
        // Petición a la API OSRM (Open Source Routing Machine)
        const response = await fetch(
          `https://router.project-osrm.org/route/v1/driving/${longitudePickup},${latitudePickup};${longitudeDestination},${latitudeDestination}?overview=full&geometries=geojson`
        );
        const data = await response.json();

        if (data.routes && data.routes.length > 0) {
          const route = data.routes[0];
          const routeGeoJSON = {
            type: 'Feature',
            geometry: route.geometry,
          };

          // Añadimos la fuente de datos al mapa
          this.map.addSource(this.routeLayerId, {
            type: 'geojson',
            data: routeGeoJSON,
          });

          // Añadimos la capa de la ruta
          this.map.addLayer({
            id: this.routeLayerId,
            type: 'line',
            source: this.routeLayerId,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#0FC938',
              'line-width': 5,
            },
          });

          // Ajustamos el mapa a la ruta
          const bounds = new mapboxgl.LngLatBounds();
          route.geometry.coordinates.forEach((coord) => bounds.extend(coord));
          this.map.fitBounds(bounds, { padding: 50 });

          // Animar el punto que recorre la ruta (opcional)
          this.animateRoute(route.geometry.coordinates);
        }
      } catch (error) {
        console.error('Error al obtener la ruta:', error);
      }
    },
    animateRoute(coordinates) {
      // Fuente para el punto animado
      const source = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      };

      // Añadimos la fuente "animatedRoute" al mapa
      this.map.addSource('animatedRoute', source);

      // Capa para el punto animado
      const layer = {
        id: 'animatedRoute',
        type: 'circle',
        source: 'animatedRoute',
        paint: {
          'circle-radius': 7,
          'circle-color': '#0FC938',
        },
      };

      this.map.addLayer(layer);

      let index = 0;
      const animate = () => {
        if (index >= coordinates.length) {
          index = 0;
        }
        // Actualizamos la posición del punto
        source.data.features = [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: coordinates[index],
            },
          },
        ];
        this.map.getSource('animatedRoute').setData(source.data);
        index++;
      };

      // Mueve el punto cada 100ms
      this.animationInterval = setInterval(animate, 100);
    },

    // <-- mira aquí: Función para aplicar manualPrice
    applyManualPrice() {
      const val = parseFloat(this.manualPrice);
      if (isNaN(val) || val <= 0) {
        alert('Por favor, introduce una cantidad válida.');
        return;
      }
      this.estimatedPrice = val;
      // Ajustamos sliderValue para reflejar el nuevo precio en %
      if (this.basePrice > 0) {
        const newSlider = (val / this.basePrice) * 100;
        if (newSlider < 50) {
          this.sliderValue = 50;
        } else if (newSlider > 200) {
          this.sliderValue = 200;
        } else {
          this.sliderValue = parseFloat(newSlider.toFixed(2));
        }
      }
    },

    /**
     * Procesar el pago con tarjeta (Stripe)
     */
    async submitPayment() {
      // Esperamos a que Vue actualice la reactividad
      await this.$nextTick();

      const tripId = localStorage.getItem('TripId');
      if (!tripId) {
        console.error('No se encontró TripId en localStorage.');
        alert('No se pudo encontrar el ID del viaje. Por favor, vuelve a solicitar el viaje.');
        return;
      }

      // Cobrar el total del precio final:
      const amountInCents = Math.round(this.estimatedPrice * 100);

      try {
        // 1. Crear el PaymentIntent en el backend
        const response = await axios.post('http://127.0.0.1:8000/api/process_payment/', {
          trip_id: tripId,
          payment_method_type: 'Card',
          amount_in_cents: amountInCents,
        });

        const clientSecret = response.data.client_secret;
        if (!clientSecret) {
          alert('Error: no se recibió un client_secret válido desde el backend.');
          return;
        }

        // 2. Confirmar el pago con Stripe
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.cardHolder.trim() || 'Cliente',
            },
          },
        });

        if (error) {
          console.error('Error en la confirmación del pago:', error);
          alert('Error en la confirmación del pago. Por favor, inténtalo de nuevo.');
        } else if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'requires_capture') {
          // 3. Actualizar estado del pago en el backend
          await axios.post('http://127.0.0.1:8000/api/update_payment_status/', {
            payment_intent_id: paymentIntent.id,
            status: paymentIntent.status === 'succeeded' ? 'Completed' : 'Pending Capture',
          });

          // 4. Marcar el pago como procesado
          this.paymentProcessed = true;
          this.startAnimation();
        }
      } catch (error) {
        console.error('Error al procesar el pago:', error);
        alert('Error al procesar el pago. Por favor, inténtalo de nuevo.');
      }
    },

    startAnimation() {
      this.currentAnimationStep = -1;
      this.showNextAnimationStep();
    },
    showNextAnimationStep() {
      this.currentAnimationStep++;
      if (this.currentAnimationStep < this.animationSteps.length) {
        const step = this.animationSteps[this.currentAnimationStep];
        setTimeout(() => {
          this.showNextAnimationStep();
        }, step.duration * 1000);
      }
      // Animación completada al final
    },
  },
  computed: {
    // Clase que cambia el color del recuadro de porcentaje
    pujaColorClass() {
      if (this.sliderValue === 100) {
        return 'bg-secondary';
      } else if (this.sliderValue > 100) {
        return 'bg-success';
      } else {
        return 'bg-warning';
      }
    },
    formattedDateTime() {
      const dateTime = this.tripDetails.dateTime;
      const date = new Date(dateTime);
      if (isNaN(date.getTime())) {
        return new Date().toLocaleString('es-ES', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      }
      return date.toLocaleString('es-ES', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
};
</script>

<style scoped>
@import '@mdi/font/css/materialdesignicons.css';

/* MAPA */
.map-container {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
}

/* PRECIO */
.price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0fc938;
}

/* BOTÓN SOLICITAR VIAJE */
.solicitar-viaje-btn {
  background-color: #0fc938;
  border-color: #0fc938;
  padding: 10px;
  font-size: 1rem;
}

.solicitar-viaje-btn:hover {
  background-color: #0da32f;
  border-color: #0da32f;
}

/* ICONOS DE PAGO */
.payment-icon {
  width: 50px;
  height: auto;
}

.payment-icons img {
  width: 60px;
}

/* TEXTO DE PAGO SEGURO */
.secure-payment {
  color: #0fc938;
  font-weight: bold;
  font-size: 1.2rem;
}

/* ANIMACIÓN DE ASIGNACIÓN DE VEHÍCULO */
.animation-section {
  padding: 20px;
}

.timeline {
  list-style: none;
  padding: 0;
  position: relative;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #e9ecef;
}

.timeline li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 60px;
}

.timeline li.active .timeline-icon {
  background: #0fc938;
  color: #fff;
}

.timeline-icon {
  position: absolute;
  left: 15px;
  top: 0;
  width: 30px;
  height: 30px;
  background: #e9ecef;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #6c757d;
}

.timeline-content {
  padding: 5px 10px;
  background: #f8f9fa;
  border-radius: 5px;
}

.timeline-content p {
  margin: 0;
}

.timeline-check {
  position: absolute;
  right: 15px;
  top: 0;
  font-size: 20px;
  color: #0fc938;
}

.vector-images {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.vector-icon {
  font-size: 50px;
  color: #0fc938;
  margin: 0 10px;
}

/* STRIPE CARD */
#card-element {
  padding: 10px;
}

/* -- ESTILOS SISTEMA DE PUJA -- */

/* Marco tipo "trading frame" */
.trading-frame {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background: #fefefe;
  padding: 15px;
}

.trading-frame-header {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 8px;
}

.trading-body {
  padding-top: 10px;
}

/* Slider e input más compactos */
.slider-trading {
  width: 150px;
  margin: 0;
}

.slider-section {
  gap: 8px;
}

/* Indicador de porcentaje */
.puja-info {
  border-radius: 4px;
  font-size: 0.85rem;
  padding: 4px 8px;
  min-width: 80px;
  text-align: center;
}

/* Label pequeño */
.small-label {
  font-size: 0.9rem;
}

/* <-- mira aquí: reduciendo campo manual */
.manual-input-container {
  max-width: 200px; /* ajusta a tu gusto */
}

.manual-input {
  min-width: 60px;
  /* ajusta también aquí el tamaño si quieres */
  text-align: right;
}
</style>
