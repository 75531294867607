<template>
  <div 
      :class="['modal fade', { show: showTripRequestModal }]" 
      tabindex="-1" 
      role="dialog" 
      :style="{ display: showTripRequestModal ? 'block' : 'none' }" 
      aria-modal="true"
  >
    <div role="document">
      <div class="modal-content custom-modal-content">
        <div class="modal-body">
          <div class="container-fluid position-relative">
            <!-- Inputs de Ubicación -->
            <div class="row g-3">
              <!-- Ubicación de Recogida -->
              <div class="col-lg-6 col-md-12">
                <div class="mb-3">
                  <label for="pickupLocation" class="form-label">
                    <box-icon name='map'></box-icon> Ubicación de Recogida
                  </label>
                  <div class="input-icon-wrapper">
                    <button @click="setCurrentLocation" class="icon-button">
                      <box-icon type="solid" name="map-pin" color="#0FC938"></box-icon>
                    </button>
                    <input 
                      type="text" 
                      class="form-control" 
                      id="pickupLocation" 
                      v-model="pickupLocation" 
                      placeholder="Ingrese la ubicación de recogida" 
                    />
                  </div>
                </div>
              </div>
              <!-- Destino -->
              <div class="col-lg-6 col-md-12">
                <div class="mb-3">
                  <label for="destinationLocation" class="form-label">
                    <box-icon name='map'></box-icon> Destino
                  </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="destinationLocation" 
                    v-model="destinationLocation" 
                    placeholder="Ingrese el destino" 
                  />
                </div>
              </div>
            </div>
            <!-- Botones Calcular Ruta y Reiniciar Búsqueda -->
            <div class="row">
              <div class="col-12 text-center">
                <button class="btn btn-primary mt-2" @click="calculateRoute">Calcular Ruta</button>
                <button class="btn btn-secondary mt-2 ms-2" @click="resetSearch">Reiniciar Búsqueda</button>
              </div>
            </div>
            <!-- Fila Principal con Mapa y Elementos -->
            <div class="row mt-3">
              <!-- Columna del Mapa (60%) -->
              <div class="col-lg-7 mb-3">
                <div ref="routeMap" class="map-container" :style="{ height: mapHeight }"></div>
              </div>
              <!-- Columna de Elementos (40%) -->
              <div class="col-lg-5">
                <!-- Tipo de Vehículo -->
                <div class="mb-3">
                  <label class="form-label">Tipo de Vehículo</label>
                  <div class="seat-options d-flex">
                    <button 
                      @click="setSelectedSeats('SAVE')" 
                      :class="['btn btn-outline-primary', { active: selectedSeats === 'SAVE' }]"
                    >
                      <box-icon type='solid' name='leaf'></box-icon> 5 Save
                    </button>
                    <button 
                      @click="setSelectedSeats('PREMIUM')" 
                      :class="['btn btn-outline-primary', { active: selectedSeats === 'PREMIUM' }]"
                    >
                      <box-icon name='car'></box-icon> 5 Premium
                    </button>
                    <button 
                      @click="setSelectedSeats('VAN')" 
                      :class="['btn btn-outline-primary', { active: selectedSeats === 'VAN' }]"
                    >
                      <box-icon name='bus'></box-icon> 8 VAN
                    </button>
                  </div>
                </div>
                <!-- Tipo de Viaje -->
                <div class="mb-3">
                  <label class="form-label">Tipo de Viaje</label>
                  <div class="d-flex align-items-center">
                    <div class="form-check me-3">
                      <input 
                        class="form-check-input" 
                        type="radio" 
                        name="tripType" 
                        id="now" 
                        value="now" 
                        v-model="tripType" 
                        @change="setTripType('now')" 
                      />
                      <label class="form-check-label" for="now">Ahora</label>
                    </div>
                    <div class="form-check">
                      <input 
                        class="form-check-input" 
                        type="radio" 
                        name="tripType" 
                        id="scheduled" 
                        value="scheduled" 
                        v-model="tripType" 
                        @change="setTripType('scheduled')" 
                      />
                      <label class="form-check-label" for="scheduled">Programar</label>
                    </div>
                  </div>
                  <div v-if="tripType === 'scheduled'" class="mt-2">
                    <label for="dateTime" class="form-label">Fecha y Hora de Reserva</label>
                    <input 
                      type="datetime-local" 
                      id="dateTime" 
                      v-model="dateTime" 
                      class="form-control small-datetime-input" 
                    />
                  </div>
                </div>
                <!-- Método de Pago -->
                <div class="mb-3">
                  <label class="form-label">Método de Pago</label>
                  <div class="payment-method d-flex gap-2">
                    <button 
                      @click="setPaymentMethod('Cash')" 
                      :class="['payment-method-button', { active: paymentMethod === 'Cash' }]"
                    >
                      <box-icon name='money'></box-icon> Efectivo
                    </button>
                    <button 
                      @click="setPaymentMethod('Card')" 
                      :class="['payment-method-button', { active: paymentMethod === 'Card' }]"
                    >
                      <box-icon name='credit-card' type='solid'></box-icon> Tarjeta
                    </button>
                  </div>
                </div>
                <!-- Teléfono de Pasajero (con selector de país) -->
                <div class="mb-3">
                  <label for="passengerPhone" class="form-label">Teléfono del Pasajero</label>
                  <!-- Input-group con el select y el input -->
                  <div class="input-group">
                    <!-- Selector de país -->
                    <select 
                      class="form-select" 
                      style="max-width: 120px" 
                      v-model="selectedCountryCode" 
                    >
                      <option 
                        v-for="country in countryCodes" 
                        :key="country.code" 
                        :value="country.code"
                      >
                        {{ country.label }} ({{ country.code }})
                      </option>
                    </select>
                    <!-- Input del número de teléfono -->
                    <input 
                      type="text" 
                      class="form-control" 
                      id="passengerPhone" 
                      v-model="passengerPhone" 
                      placeholder="Ingrese su número de teléfono" 
                    />
                  </div>
                </div>
                <!-- Panel de Precios -->
                <div class="mb-3 price-details">
                  <div class="total-price mb-2">
                    <h5>Precio Estimado:</h5>
                    <p>
                      <strong>Total:</strong>
                      <span> €{{ estimatedPrice.toFixed(2) }}</span>
                    </p>
                  </div>
                  <div class="price-info d-flex flex-wrap">
                    <div class="price-item flex-fill">
                      <p>
                        <i class="bx bx-car"></i>
                        <strong> Distancia:</strong> {{ distance.toFixed(2) }} km
                      </p>
                    </div>
                    <div class="price-item flex-fill">
                      <p>
                        <i class="bx bx-time"></i>
                        <strong> Tiempo de Espera:</strong> {{ waitingTime }} min
                      </p>
                    </div>
                    <div class="price-item flex-fill">
                      <p>
                        <i class="bx bx-money"></i>
                        <strong> Coste por Distancia:</strong> €{{ distanceCost.toFixed(2) }}
                      </p>
                    </div>
                    <div class="price-item flex-fill">
                      <p>
                        <i class="bx bx-time-five"></i>
                        <strong> Coste por Tiempo de Espera:</strong> €{{ waitTimeCost.toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Botón de Solicitar Viaje -->
                <div class="text-center">
                  <button type="button" class="btn custom-btn-request" @click="requestTrip">
                    Solicitar Viaje
                  </button>
                </div>
              </div>
            </div>
            <!-- Fin de la Fila Principal -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mapboxgl from 'mapbox-gl';

export default {
  name: 'RequestTrip',
  data() {
    return {
      showTripRequestModal: true,
      isFullscreen: false,
      pickupLocation: '',
      destinationLocation: '',
      passengerPhone: '', // Teléfono vacío por defecto
      // Lista de códigos de país
      countryCodes: [
      { code: '+34', label: 'ES' },  // España
      { code: '+31', label: 'NL' },  // Holanda
      { code: '+33', label: 'FR' },  // Francia
      { code: '+44', label: 'UK' },  // Reino Unido
      { code: '+49', label: 'DE' },  // Alemania
      { code: '+39', label: 'IT' },  // Italia
      { code: '+41', label: 'CH' },  // Suiza
      { code: '+47', label: 'NO' },  // Noruega
      { code: '+1',  label: 'US' },  // Estados Unidos
      { code: '+46', label: 'SE' }   // Suecia
      ],
      selectedCountryCode: '+34', // Código de país por defecto

      map: null,
      distance: 0,
      estimatedPrice: 0,
      distanceCost: 0,
      waitTimeCost: 0,
      waitingTime: 0,
      selectedSeats: 'SAVE',
      paymentMethod: 'Card', 
      tripType: 'now',
      dateTime: null,
      selectedLanguage: 'es',
      latitudePickup: null,
      longitudePickup: null,
      latitudeDestination: null,
      longitudeDestination: null,
      routeLayer: null,
      modalWidth: '80%',
      modalHeight: '80%',
      mapHeight: '500px',
      pickupMarker: null,
      destinationMarker: null,
      routeDuration: null, 
      tripId: null, 
      // Token de acceso de Mapbox
      mapboxAccessToken: 'pk.eyJ1Ijoic2FtaWdvcmFiIiwiYSI6ImNtMnNnbGhzdTFjZzEya3NibHd1ZzB0ZXQifQ.g7lY1cJ9CUqUR4uCC_fvBA',
    };
  },
  methods: {
    initMap() {
      if (this.map) return;

      mapboxgl.accessToken = this.mapboxAccessToken;

      this.map = new mapboxgl.Map({
        container: this.$refs.routeMap,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-4.4991, 36.6213],
        zoom: 4,
      });

      // Agregar controles de navegación
      this.map.addControl(new mapboxgl.NavigationControl());
    },

    async calculateRoute() {
      if (!this.pickupLocation || !this.destinationLocation) {
        this.showMessage('Por favor, ingrese ambas ubicaciones.');
        return;
      }
      try {
        // Obtener coordenadas de origen
        await this.fetchCoordinates('pickup');
        // Obtener coordenadas de destino
        await this.fetchCoordinates('destination');

        if (
          !this.latitudePickup || 
          !this.longitudePickup || 
          !this.latitudeDestination || 
          !this.longitudeDestination
        ) {
          this.showMessage('No se pudieron obtener las coordenadas de las ubicaciones.');
          return;
        }

        // Crear marcadores en el mapa
        this.addMarker([this.longitudePickup, this.latitudePickup], 'green', 'pickup');
        this.addMarker([this.longitudeDestination, this.latitudeDestination], 'red', 'destination');

        // Ajustar el mapa a ambos marcadores
        this.adjustMapBounds();

        // Calcular ruta
        await this.getRoute();

        // Guardar en localStorage
        this.saveDataToLocalStorage();

        // Deshabilitar la interacción con el mapa
        this.disableMapInteraction();
      } catch (error) {
        console.error('Error al calcular la ruta:', error);
        this.showMessage('Ocurrió un error al calcular la ruta.');
      }
    },

    async fetchCoordinates(type) {
      const location =
        type === 'pickup' ? this.pickupLocation : this.destinationLocation;
      try {
        const response = await axios.get('https://nominatim.openstreetmap.org/search', {
          params: {
            q: location,
            format: 'json',
            limit: 1,
          },
        });
        if (response.data && response.data.length > 0) {
          const result = response.data[0];
          const lat = parseFloat(result.lat);
          const lon = parseFloat(result.lon);
          if (type === 'pickup') {
            this.latitudePickup = lat;
            this.longitudePickup = lon;
          } else {
            this.latitudeDestination = lat;
            this.longitudeDestination = lon;
          }
        } else {
          this.showMessage(`No se encontró la ubicación: ${location}`);
        }
      } catch (error) {
        console.error(`Error en la búsqueda de la ubicación (${location}):`, error);
        this.showMessage(`Error en la búsqueda de la ubicación: ${location}`);
      }
    },

    addMarker(coords, color, type) {
      if (type === 'pickup' && this.pickupMarker) {
        this.pickupMarker.remove();
      }
      if (type === 'destination' && this.destinationMarker) {
        this.destinationMarker.remove();
      }

      const marker = new mapboxgl.Marker({ color })
        .setLngLat(coords)
        .addTo(this.map);

      if (type === 'pickup') {
        this.pickupMarker = marker;
      } else if (type === 'destination') {
        this.destinationMarker = marker;
      }
    },

    adjustMapBounds() {
      const bounds = new mapboxgl.LngLatBounds();
      if (this.longitudePickup && this.latitudePickup) {
        bounds.extend([this.longitudePickup, this.latitudePickup]);
      }
      if (this.longitudeDestination && this.latitudeDestination) {
        bounds.extend([this.longitudeDestination, this.latitudeDestination]);
      }
      if (!bounds.isEmpty()) {
        this.map.fitBounds(bounds, { padding: 50 });
      }
    },

    async getRoute() {
      try {
        const mapboxRoute = await this.getRouteMapbox();
        if (mapboxRoute) {
          this.processRoute(mapboxRoute.geometry.coordinates, mapboxRoute.distance);
          return;
        }
      } catch (error) {
        console.warn('Mapbox Directions API falló, intentando con OSRM.', error);
      }

      try {
        const osrmRoute = await this.getRouteOSRM();
        if (osrmRoute) {
          this.processRoute(osrmRoute.geometry.coordinates, osrmRoute.distance);
          return;
        }
      } catch (error) {
        console.error('Ambos servicios de rutas fallaron:', error);
        this.showMessage('No se pudo calcular la ruta con los servicios disponibles.');
      }
    },

    async getRouteMapbox() {
      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${this.longitudePickup},${this.latitudePickup};${this.longitudeDestination},${this.latitudeDestination}`;
      try {
        const response = await axios.get(url, {
          params: {
            alternatives: false,
            geometries: 'geojson',
            steps: false,
            access_token: this.mapboxAccessToken,
          },
        });
        if (response.data && response.data.routes && response.data.routes.length > 0) {
          const route = response.data.routes[0];
          this.distance = route.distance / 1000; // en kilómetros
          this.routeDuration = route.duration;   // en segundos
          // Recalcular precio según nueva distancia
          this.recalculatePrice();

          return {
            geometry: route.geometry,
            distance: route.distance,
            duration: route.duration,
          };
        } else {
          this.showMessage('Mapbox: No se pudo calcular la ruta.');
          return null;
        }
      } catch (error) {
        console.error('Error al obtener la ruta de Mapbox:', error);
        this.showMessage('Error al obtener la ruta de Mapbox.');
        throw error;
      }
    },

    async getRouteOSRM() {
      try {
        const response = await axios.get(
          `https://router.project-osrm.org/route/v1/driving/${this.longitudePickup},${this.latitudePickup};${this.longitudeDestination},${this.latitudeDestination}`,
          {
            params: {
              overview: 'full',
              geometries: 'geojson',
            },
          }
        );
        if (response.data && response.data.routes && response.data.routes.length > 0) {
          const route = response.data.routes[0];
          this.distance = route.distance / 1000; // en kilómetros
          this.routeDuration = route.duration;   // en segundos
          // Recalcular precio según nueva distancia
          this.recalculatePrice();

          return {
            geometry: route.geometry,
            distance: route.distance,
            duration: route.duration,
          };
        } else {
          this.showMessage('OSRM: No se pudo calcular la ruta.');
          return null;
        }
      } catch (error) {
        console.error('Error al obtener la ruta de OSRM:', error);
        this.showMessage('Error al obtener la ruta de OSRM.');
        throw error;
      }
    },

    processRoute(coordinates) {
      const routeGeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      };
      if (this.map.getSource('route')) {
        this.map.removeLayer('route');
        this.map.removeSource('route');
      }
      this.map.addSource('route', {
        type: 'geojson',
        data: routeGeoJSON,
      });
      this.map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#0B9A2B',
          'line-width': 7,
        },
      });
    },

    calculateRidePrice(paymentType, serviceType, distance, isImmediate) {
      const basePriceSave = 7.75; 
      const costPerKm = 1.38;     
      const costPerMinuteWait = 0.52; 

      const serviceMultipliers = {
        SAVE: 1,
        PREMIUM: 1.25,
        VAN: 1.35,
      };

      const serviceMultiplier = serviceMultipliers[serviceType.toUpperCase()];
      if (!serviceMultiplier) {
        throw new Error('Tipo de servicio inválido. Elige entre SAVE, PREMIUM o VAN.');
      }

      let waitTimeMinutes;
      if (distance <= 5) {
        waitTimeMinutes = 2; 
      } else if (distance <= 15) {
        waitTimeMinutes = 5; 
      } else {
        waitTimeMinutes = 10; 
      }

      // Calcular el precio base
      const baseRidePrice = basePriceSave * serviceMultiplier;
      let distanceCost = distance * costPerKm;
      let waitTimeCost = waitTimeMinutes * costPerMinuteWait;

      // Ajuste 10% para efectivo
      if (paymentType.toLowerCase() === 'efectivo') {
        distanceCost *= 1.1;
        waitTimeCost *= 1.1;
      }

      // Ajuste 10% para viajes inmediatos
      if (isImmediate) {
        distanceCost *= 1.1;
        waitTimeCost *= 1.1;
      }

      let totalCost = baseRidePrice + distanceCost + waitTimeCost;

      totalCost = totalCost.toFixed(2);
      distanceCost = distanceCost.toFixed(2);
      waitTimeCost = waitTimeCost.toFixed(2);

      return {
        serviceType,
        paymentType,
        distance,
        isImmediate,
        waitTimeMinutes,
        totalCost: parseFloat(totalCost),
        distanceCost: parseFloat(distanceCost),
        waitTimeCost: parseFloat(waitTimeCost),
      };
    },

    recalculatePrice() {
      const paymentType = this.paymentMethod;
      const serviceType = this.selectedSeats;
      const isImmediate = this.tripType === 'now';

      // Convertir 'Card' a 'tarjeta', 'Cash' a 'efectivo'
      const paymentTypeFormatted =
        paymentType.toLowerCase() === 'cash' ? 'efectivo' : 'tarjeta';

      const result = this.calculateRidePrice(
        paymentTypeFormatted,
        serviceType,
        this.distance,
        isImmediate
      );

      this.estimatedPrice = result.totalCost;
      this.distanceCost = result.distanceCost;
      this.waitTimeCost = result.waitTimeCost;
      this.waitingTime = result.waitTimeMinutes;
    },

    disableMapInteraction() {
      if (!this.map) return;
      this.map.scrollZoom.disable();
      this.map.dragPan.disable();
      this.map.dragRotate.disable();
      this.map.keyboard.disable();
      this.map.doubleClickZoom.disable();
      this.map.touchZoomRotate.disable();
    },

    saveDataToLocalStorage() {
      localStorage.setItem('Origen', this.pickupLocation);
      localStorage.setItem('Destino', this.destinationLocation);
      // Guardamos teléfono completo (código país + teléfono)
      const fullPhone = this.selectedCountryCode + this.passengerPhone;
      localStorage.setItem('TelefonoPasajero', fullPhone);

      localStorage.setItem('FechaYHora', this.dateTime);
      localStorage.setItem('TipoDeViaje', this.tripType);
      localStorage.setItem('VehiculoSeleccionado', this.selectedSeats);
      localStorage.setItem('MetodoDePago', this.paymentMethod);
      localStorage.setItem('LatitudOrigen', this.latitudePickup?.toString() || '');
      localStorage.setItem('LongitudOrigen', this.longitudePickup?.toString() || '');
      localStorage.setItem('LatitudDestino', this.latitudeDestination?.toString() || '');
      localStorage.setItem('LongitudDestino', this.longitudeDestination?.toString() || '');
      localStorage.setItem('PrecioEstimado', this.estimatedPrice.toString());
    },

    setCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.map.flyTo({
              center: [longitude, latitude],
              zoom: 14,
            });
            if (this.pickupMarker) {
              this.pickupMarker.setLngLat([longitude, latitude]);
            } else {
              this.pickupMarker = new mapboxgl.Marker({ color: 'blue' })
                .setLngLat([longitude, latitude])
                .addTo(this.map);
            }
            this.pickupLocation = `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`;
          },
          (error) => {
            console.error('Error al obtener la ubicación:', error);
            this.showMessage('No se pudo obtener la ubicación.');
          }
        );
      } else {
        this.showMessage('La geolocalización no es compatible con este navegador.');
      }
    },

    setPaymentMethod(method) {
      this.paymentMethod = method;
      if (this.distance > 0) {
        this.recalculatePrice();
        localStorage.setItem('MetodoDePago', this.paymentMethod);
        localStorage.setItem('PrecioEstimado', this.estimatedPrice.toString());
      }
    },

    setSelectedSeats(seats) {
      this.selectedSeats = seats;
      if (this.distance > 0) {
        this.recalculatePrice();
        localStorage.setItem('VehiculoSeleccionado', this.selectedSeats);
        localStorage.setItem('PrecioEstimado', this.estimatedPrice.toString());
      }
    },

    setTripType(type) {
      this.tripType = type;
      if (this.distance > 0) {
        this.recalculatePrice();
        localStorage.setItem('TipoDeViaje', this.tripType);
        localStorage.setItem('PrecioEstimado', this.estimatedPrice.toString());
      }
    },

    async requestTrip() {
      if (!this.pickupLocation || !this.destinationLocation) {
        this.showMessage('Por favor, completa las ubicaciones.');
        return;
      }
      if (!this.passengerPhone || !this.passengerPhone.trim()) {
        this.showMessage('Por favor, ingresa tu número de teléfono.');
        return;
      }
      // Concatenar el país + teléfono
      const fullPhone = this.selectedCountryCode + this.passengerPhone;

      // Estimar dropoff_datetime basado en la duración del viaje
      let dropoffDatetime = null;
      if (this.routeDuration) {
        const estimatedArrivalTime = new Date();
        estimatedArrivalTime.setSeconds(
          estimatedArrivalTime.getSeconds() + this.routeDuration
        );
        dropoffDatetime = estimatedArrivalTime.toISOString();
      }

      const tripData = {
        passenger_phone: fullPhone,
        pickup_location: this.pickupLocation,
        dropoff_location: this.destinationLocation,
        vehicle_type: this.selectedSeats,
        requested_at: new Date().toISOString(),
        is_scheduled: this.tripType === 'scheduled',
        pickup_datetime:
          this.tripType === 'scheduled' && this.dateTime
            ? new Date(this.dateTime).toISOString()
            : new Date().toISOString(),
        dropoff_datetime: dropoffDatetime,
        distance_km: this.distance.toFixed(2),
        estimated_price: this.estimatedPrice.toFixed(2),
        total_price: this.estimatedPrice.toFixed(2),
        user: null,
      };

      console.log('Datos enviados (requestTrip):', tripData);

      const localEndpoint = 'http://127.0.0.1:8000/api/trips/';
      const productionEndpoint = 'https://backend.greentransfer.online/api/trips/';

      try {
        let response;
        let usedEndpoint = '';

        try {
          // Intento de conexión a localhost
          console.info('Intentando usar endpoint local...');
          usedEndpoint = localEndpoint;
          response = await axios.post(localEndpoint, tripData);
        } catch (errorLocal) {
          // Si falla, usar producción
          console.warn('Fallo endpoint local, intentando producción...');
          usedEndpoint = productionEndpoint;
          response = await axios.post(productionEndpoint, tripData);
        }

        if (response && response.status === 201) {
          // Guardamos el ID del viaje
          this.tripId = response.data.id;
          localStorage.setItem('TripId', this.tripId.toString());

          console.log('Viaje creado con el endpoint:', usedEndpoint);
          this.showMessage('Viaje solicitado con éxito. Redirigiendo a pagos...');
          // Navegar a la sección de pagos
          this.$router.push('/pagos');
        } else {
          console.log('Respuesta inesperada:', response);
          this.showMessage('Hubo un problema al solicitar el viaje. Inténtalo de nuevo.');
        }
      } catch (error) {
        if (error.response) {
          console.error('Error al solicitar el viaje:', error.response.data);
          console.error('Estado:', error.response.status);
          if (error.response.data) {
            const errorMessages = Object.values(error.response.data)
              .flat()
              .join(' ');
            this.showMessage(`Error: ${errorMessages}`);
          } else {
            this.showMessage(
              'Error al conectar con la API. Por favor, verifica tu conexión o inténtalo más tarde.'
            );
          }
        } else {
          console.error('Error al solicitar el viaje:', error.message);
          this.showMessage(
            'Error al conectar con la API. Por favor, verifica tu conexión o inténtalo más tarde.'
          );
        }
      }
    },

    resetSearch() {
      this.pickupLocation = '';
      this.destinationLocation = '';
      // Si deseas resetear el código al valor por defecto
      // this.selectedCountryCode = '+34'; 
      this.passengerPhone = '';

      this.latitudePickup = null;
      this.longitudePickup = null;
      this.latitudeDestination = null;
      this.longitudeDestination = null;

      if (this.map) {
        this.map.scrollZoom.enable();
        this.map.dragPan.enable();
        this.map.dragRotate.enable();
        this.map.keyboard.enable();
        this.map.doubleClickZoom.enable();
        this.map.touchZoomRotate.enable();
      }

      if (this.pickupMarker) {
        this.pickupMarker.remove();
        this.pickupMarker = null;
      }
      if (this.destinationMarker) {
        this.destinationMarker.remove();
        this.destinationMarker = null;
      }

      if (this.map && this.map.getSource('route')) {
        this.map.removeLayer('route');
        this.map.removeSource('route');
      }

      if (this.map) {
        this.map.flyTo({ center: [-4.4991, 36.6213], zoom: 8 });
      }

      this.distance = 0;
      this.estimatedPrice = 0;
      this.distanceCost = 0;
      this.waitTimeCost = 0;
      this.waitingTime = 0;
      this.routeDuration = null;
      this.tripId = null;

      localStorage.removeItem('Origen');
      localStorage.removeItem('Destino');
      localStorage.removeItem('TelefonoPasajero');
      localStorage.removeItem('FechaYHora');
      localStorage.removeItem('TipoDeViaje');
      localStorage.removeItem('VehiculoSeleccionado');
      localStorage.removeItem('MetodoDePago');
      localStorage.removeItem('LatitudOrigen');
      localStorage.removeItem('LongitudOrigen');
      localStorage.removeItem('LatitudDestino');
      localStorage.removeItem('LongitudDestino');
      localStorage.removeItem('PrecioEstimado');
      localStorage.removeItem('TripId');

      this.showMessage('Búsqueda reiniciada.');
    },

    showMessage(message) {
      if (window.Android && typeof window.Android.showToast === 'function') {
        window.Android.showToast(message);
      } else {
        alert(message);
      }
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>

<style scoped>
/* Ajustes del modal */
.modal-dialog {
  max-width: 100%;
  margin: auto;
}

.modal-fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
}

.modal-content {
  height: 100%;
}

@media (max-width: 576px) {
  .modal-dialog {
    width: 100%;
    margin: 0;
  }
}

.map-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.mapboxgl-ctrl {
  z-index: 1;
}

.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-top-left {
  top: 70px;
}

.price-details {
  background-color: #3b3b3b;
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  font-size: 1rem;
}

.price-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.price-item {
  flex: 1 1 45%;
  margin-bottom: 10px;
}

.price-item p {
  margin: 0;
}

.btn-primary {
  background-color: #0FC938;
  border-color: #0FC938;
}

.btn-primary:hover {
  background-color: #0B9A2B;
  border-color: #0B9A2B;
}

.custom-btn-request {
  background-color: #0FC938;
  color: #ffffff;
  border: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.custom-btn-request:hover {
  background-color: #0B9A2B;
}

.language-switch button,
.payment-method-button.active,
.seat-options button.active {
  background-color: #0FC938;
  color: #ffffff;
  border-color: #0FC938;
}

.custom-modal-content {
  border-radius: 10px;
}

.modal-header {
  background-color: #0FC938;
  color: #ffffff;
  border-bottom: none;
}

.modal-header .btn-close {
  background-color: #ffffff;
  opacity: 1;
}

.custom-fullscreen-btn,
.custom-close-btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
}

.language-switch button {
  border: none;
  background-color: transparent;
  margin-right: 5px;
  font-size: 1rem;
}

.language-switch button.active {
  background-color: #0FC938;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
}

.payment-method-button {
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  color: #000000;
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-method-button.active {
  background-color: #0FC938;
  color: #ffffff;
  border-color: #0FC938;
}

.seat-options {
  flex-wrap: wrap;
}

.seat-options button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.seat-options button.active {
  background-color: #0FC938;
  color: #ffffff;
  border-color: #0FC938;
}

.price-details .price-info p {
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .modal-dialog {
    width: 100%;
  }
  .seat-options {
    flex-direction: column;
  }
  .seat-options button {
    margin-bottom: 10px;
  }
}

.price-details h5 {
  color: #15f746;
}

.input-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  left: 10px;
  pointer-events: auto;
}

#pickupLocation {
  padding-left: 35px;
}

/* Ajustar el tamaño del input de fecha/hora */
.small-datetime-input {
  max-width: 200px;
}
</style>
