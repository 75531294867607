<template>
  <!-- Contenedor principal de conductores -->
  <div class="drivers-container">
    <!-- Encabezado -->
    <div class="drivers-header" style="background-color: #4DB82B; color: white; padding: 15px;">
      <h5 class="mb-0">Lista de Conductores</h5>
      <!-- Botón de pantalla completa -->
      <div class="ms-auto d-flex align-items-center">
        <button type="button" class="btn btn-light" @click="toggleFullscreenDrivers" title="Pantalla Completa">
          <i :class="['mdi', isFullscreenDrivers ? 'mdi-fullscreen-exit' : 'mdi-fullscreen']"></i>
        </button>
      </div>
    </div>

    <!-- Cuerpo -->
    <div class="drivers-body" :class="{ 'fullscreen': isFullscreenDrivers }">
      <!-- Mapa de Conductores -->
      <div style="height: 400px; width: 100%; margin-bottom: 20px;">
        <MapDrivers :drivers="drivers" :userLocation="userLocation" />
      </div>
      <!-- Paginador y Promedios -->
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
          <p><strong>Precio medio por kilómetro:</strong> €{{ averagePricePerKm }}/km</p>
          <p><strong>Comisión media:</strong> {{ averageCommission }}%</p>
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination mb-0">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="prevPage">Anterior</a>
            </li>
            <li class="page-item disabled">
              <span class="page-link">Página {{ currentPage }} de {{ totalPages }}</span>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="nextPage">Siguiente</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- Lista de conductores paginada -->
      <div v-for="driver in paginatedDrivers" :key="driver.id" class="driver-card mb-4 p-3 border rounded bg-light">
        <div class="row align-items-center">
          <!-- Imagen del conductor -->
          <div class="col-md-2 text-center">
            <img :src="driver.photo" class="rounded-circle driver-photo img-fluid" alt="Foto del conductor">
            <p class="mt-2"><strong>{{ driver.distance.toFixed(2) }} km</strong></p>
          </div>
          <!-- Información del conductor -->
          <div class="col-md-7">
            <h5>{{ driver.full_name }}</h5>
            <div class="row bg-light p-2 rounded">
              <!-- Badges con información -->
              <div class="col-sm-4 mb-2" v-for="(badge, index) in driverBadges(driver)" :key="index">
                <span :class="['badge', badge.class]">
                  <i :class="['mdi', badge.icon, 'mdi-18px', 'me-1']"></i>
                  {{ badge.text }}
                </span>
              </div>
            </div>
          </div>
          <!-- Imagen del vehículo y detalles -->
          <div class="col-md-3 text-center">
            <img :src="driver.vehiclePhoto" class="vehicle-photo img-fluid mb-2 shadow-sm" alt="Foto del vehículo">
            <p><strong>{{ driver.vehicleModel }}</strong></p>
            <p>{{ driver.vehicleType }}</p>
            <!-- Icono tipo insignia -->
            <i :class="['mdi', badgeIcons[driver.id % badgeIcons.length], 'mdi-24px', 'text-primary']"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapDrivers from './MapDrivers.vue'; // Asegúrate de que la ruta es correcta

export default {
  name: 'DriverInfo',
  components: {
    MapDrivers,
  },
  data() {
    return {
      isFullscreenDrivers: false, // Estado de pantalla completa
      currentPage: 1,
      driversPerPage: 5,
      userLocation: null, // Ubicación del usuario
      drivers: [], // Datos de los conductores
      badgeIcons: ['mdi-shield-check', 'mdi-shield-star', 'mdi-shield-account'], // Iconos de insignia
    };
  },
  created() {
    this.getUserLocation(); // Solicitamos la ubicación al cargar el componente
    this.loadDrivers();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.drivers.length / this.driversPerPage);
    },
    paginatedDrivers() {
      const start = (this.currentPage - 1) * this.driversPerPage;
      return this.drivers.slice(start, start + this.driversPerPage);
    },
    averagePricePerKm() {
      if (this.drivers.length === 0) return 0;
      const totalPrice = this.drivers.reduce((sum, driver) => sum + parseFloat(driver.price_per_km), 0);
      return (totalPrice / this.drivers.length).toFixed(2);
    },
    averageCommission() {
      if (this.drivers.length === 0) return 0;
      const totalCommission = this.drivers.reduce((sum, driver) => sum + parseFloat(driver.commission), 0);
      return (totalCommission / this.drivers.length).toFixed(2);
    },
  },
  methods: {
    async loadDrivers() {
      try {
        const response = await fetch('http://127.0.0.1:8000/api/drivers/');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        // Mapear los datos recibidos y asignar imágenes y coordenadas aleatorias en Málaga
        const driversData = data.map((driver, index) => ({
          id: driver.id,
          full_name: driver.full_name,
          phone: driver.phone,
          email: driver.email,
          level: driver.level,
          management_level: driver.management_level,
          registration_date: driver.registration_date,
          price_per_km: driver.price_per_km,
          commission: driver.commission,
          daily_bid: driver.daily_bid,
          work_zones: driver.work_zones ? driver.work_zones.split(',').map(zone => zone.trim()) : [],
          // Asignar imágenes aleatorias
          photo: require(`@/assets/img/green/driversandcars/driver${(index % 5) + 1}.jpg`),
          vehiclePhoto: require(`@/assets/img/green/driversandcars/car${(index % 5) + 1}.jpg`),
          vehicleModel: driver.vehicle || `Modelo ${index + 1}`,
          vehicleType: index % 2 === 0 ? 'Eléctrico' : 'Híbrido',
          // Asignar coordenadas aleatorias cerca de Málaga
          latitude: 36.7213 + (Math.random() - 0.5) * 0.2, // Entre 36.6213 y 36.8213
          longitude: -4.4217 + (Math.random() - 0.5) * 0.2, // Entre -4.5217 y -4.3217
          distance: 0, // Se calculará después
        }));
        this.drivers = driversData;
        // Calcular las distancias si ya tenemos la ubicación del usuario
        if (this.userLocation) {
          this.drivers.forEach((driver) => {
            driver.distance = this.calculateDistance(
              this.userLocation.latitude,
              this.userLocation.longitude,
              driver.latitude,
              driver.longitude
            );
          });
        }
      } catch (error) {
        console.error('Error al obtener los datos de los conductores:', error);
        alert('No se pudieron cargar los datos de los conductores.');
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('es-ES', options);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    toggleFullscreenDrivers() {
      this.isFullscreenDrivers = !this.isFullscreenDrivers;
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.userLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            // Actualizar las distancias inicialmente
            this.drivers.forEach((driver) => {
              driver.distance = this.calculateDistance(
                this.userLocation.latitude,
                this.userLocation.longitude,
                driver.latitude,
                driver.longitude
              );
            });
            console.log('Ubicación del usuario:', this.userLocation);
          },
          (error) => {
            console.error('Error al obtener la ubicación:', error);
            alert('No se pudo obtener tu ubicación. Algunas funcionalidades podrían no estar disponibles.');
          }
        );
      } else {
        alert('La geolocalización no está soportada por este navegador.');
      }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radio de la Tierra en km
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a = 
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    driverBadges(driver) {
      return [
        {
          class: 'bg-primary text-white',
          icon: 'mdi-phone',
          text: `Teléfono: ${driver.phone}`,
        },
        {
          class: 'bg-secondary text-white',
          icon: 'mdi-email',
          text: `Email: ${driver.email}`,
        },
        {
          class: 'bg-success text-white',
          icon: 'mdi-star',
          text: `Nivel: ${driver.level}`,
        },
        {
          class: 'bg-info text-dark',
          icon: 'mdi-account-tie',
          text: `Gestión: ${driver.management_level}`,
        },
        {
          class: 'bg-warning text-dark',
          icon: 'mdi-calendar',
          text: `Registro: ${this.formatDate(driver.registration_date)}`,
        },
        {
          class: 'bg-dark text-white',
          icon: 'mdi-currency-eur',
          text: `Precio/Km: €${driver.price_per_km}`,
        },
        {
          class: 'bg-danger text-white',
          icon: 'mdi-percent',
          text: `Comisión: ${driver.commission}%`,
        },
        {
          class: 'bg-primary text-white',
          icon: 'mdi-gavel',
          text: `Puja Diaria: €${driver.daily_bid}`,
        },
        {
          class: 'bg-success text-white',
          icon: 'mdi-map-marker',
          text: `Zonas: ${driver.work_zones.join(', ')}`,
        },
      ];
    },
  },
};
</script>

<style scoped>
.drivers-container {
  border: 5px solid #4DB82B;
  margin-bottom: 20px;
}

.drivers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drivers-body {
  padding: 15px;
}

.drivers-body.fullscreen {
  width: 100%;
}

.driver-card {
  background-color: #f8f9fa;
  transition: box-shadow 0.3s;
  border: 1px solid #d3d3d3;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.driver-photo {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border: 3px solid #d3d3d3;
}

.vehicle-photo {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border: 4px solid #d3d3d3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.pagination .page-link {
  color: #4DB82B;
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
}

.pagination .page-link:hover {
  color: #0056b3;
}

/* Ajustes para badges con iconos */
.badge .mdi {
  vertical-align: middle;
}

/* Responsive adjustments */
@media (max-width: 767.98px) {
  .driver-photo, .vehicle-photo {
    width: 100px;
    height: 100px;
  }
}
</style>
